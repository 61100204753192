import { Image } from '@nextui-org/react';
import { Flex } from 'antd';

import Illustration from '../../../assets/image-work.png';
import { Computer, PanelsTopLeft, LayoutPanelLeft, Cloudy } from 'lucide-react';
import OurTechnologies from '../../components/technology-section/technology-section.component';
import ProcessSection from '../../components/process-section/process-section.component';
import ContactSection from '../../components/contact-section/contact-section.component';

const HomePage = () => {
	return (
		<div>
			<section className='bg-blue-700 text-white' id='about'>
				<div className='max-w-6xl mx-auto px-4 sm:px-6 lg:px-8 py-12 md:pb-20 md:pt-10'>
					<div className='flex flex-col md:flex-row items-center align-middle'>
						<div className='w-full md:w-1/2 mb-8 md:mb-0'>
							<Image
								src={Illustration}
								width={'440px'}
								className='my-8 w-full h-auto opacity-100'
							/>
						</div>
						<div className='w-full md:w-1/2 md:pl-8 text-center md:text-right'>
							<h1 className='text-3xl sm:text-4xl lg:text-5xl font-bold lg:leading-tight mb-4'>
								Transforming Ideas into Digital Brilliance
							</h1>
							<p className='text-base md:text-lg leading-relaxed'>
								We simplify complexity with custom digital tools that
								align with your goals. Let us transform your challenges
								into opportunities for growth. From ideation to
								execution, we build software that powers your vision.
							</p>
						</div>
					</div>
				</div>
			</section>
			<section
				className='h-auto bg-white max-w-6xl mx-auto p-8'
				id='services'
			>
				<Flex vertical>
					<span className='text-blue-700 text-4xl text-center font-semibold mb-10 mt-5'>
						How We Help
					</span>
					{/* <Flex justify='space-between' wrap align='center'> */}
					<section className='mb-20 mt-5'>
						<div className='container mx-auto'>
							<div className='grid grid-cols-1 md:grid-cols-4 gap-8'>
								{[
									{
										cardIcon: Computer,
										cardTitle: 'Software Development',
										cardDescription:
											'Building software tailored to drive your ideas to success.',
									},
									{
										cardIcon: LayoutPanelLeft,
										cardTitle: 'Mobile App Development',
										cardDescription:
											'Transforming bold ideas into powerful mobile apps.',
									},
									{
										cardIcon: PanelsTopLeft,
										cardTitle: 'Web App Development',
										cardDescription:
											'Designing web apps that redefine digital journeys.',
									},
									{
										cardIcon: Cloudy,
										cardTitle: 'Cloud Infrastructure and Deployment',
										cardDescription:
											'Powering scalability with robust cloud technologies.',
									},
								].map((item, index) => (
									<div
										className='bg-white p-6 rounded-lg shadow-md border'
										key={index}
									>
										<Flex vertical align='center'>
											<item.cardIcon className='text-blue-600 w-12 h-12 mb-4' />
											<h3 className='text-xl font-semibold mb-2 text-blue-700'>
												{item.cardTitle}
											</h3>
											<p className='text-gray-600'>
												{item.cardDescription}
											</p>
										</Flex>
									</div>
								))}
							</div>
						</div>
					</section>
				</Flex>
				{/* </Flex> */}
			</section>
			<OurTechnologies />
			<ProcessSection />
			<ContactSection />
		</div>
	);
};

export default HomePage;
