import { useState, useEffect } from 'react';
import {
	Accordion,
	AccordionItem,
	Button,
	Input,
	Modal,
	ModalContent,
	ModalHeader,
	ModalBody,
	useDisclosure,
	ModalFooter,
} from '@nextui-org/react';

import React from 'react';

export function BasilNotificationOnOutline(props) {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='1.75rem'
			height='1.75rem'
			viewBox='0 0 24 24'
			{...props}
		>
			<path
				fill='none'
				stroke='#1d4ed8'
				strokeLinecap='round'
				strokeLinejoin='round'
				strokeWidth={1.5}
				d='M18.134 11C18.715 16.375 21 18 21 18H3s3-2.133 3-9.6c0-1.697.632-3.325 1.757-4.525S10.41 2 12 2q.507 0 1 .09M19 8a3 3 0 1 0 0-6a3 3 0 0 0 0 6m-5.27 13a2 2 0 0 1-3.46 0'
			></path>
		</svg>
	);
}

export default function ContactSection() {
	const [showPopup, setShowPopup] = useState(false);
	const [formData, setFormData] = useState({
		name: '',
		company: '',
		project: '',
		email: '',
	});

	const handleInputChange = (e) => {
		setFormData({ ...formData, [e.target.name]: e.target.value });
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		console.log('Form submitted:', formData);
		// Here you would typically send the data to your backend
		setShowPopup(true);
	};

	useEffect(() => {
		if (showPopup) {
			const timer = setTimeout(() => {
				setShowPopup(false);
			}, 3000);
			return () => clearTimeout(timer);
		}
	}, [showPopup]);

	const { isOpen, onOpen, onOpenChange } = useDisclosure();

	return (
		<section
			className='bg-blue-700 text-white lg:p-16 py-16 px-8'
			id='contact'
		>
			<div className='max-w-6xl mx-auto flex flex-col lg:flex-row items-center justify-between gap-8'>
				<div className='w-full lg:w-1/2 text-start'>
					<h2 className='text-3xl md:text-4xl font-bold mb-4'>
						We'd love to have a
					</h2>
				</div>
				<div className='w-full lg:w-1/2 text-start'>
					<Accordion variant='splitted'>
						<AccordionItem
							key='1'
							aria-label='Accordion 1'
							title={
								<span className='text-xl font-semibold mb-2 text-blue-700'>
									New notification from an awesome guy!
								</span>
							}
							startContent={
								<BasilNotificationOnOutline className='inline mr-2' />
							}
						>
							{
								<form
									onSubmit={handleSubmit}
									className='space-y-4 text-blue-700 m-4 '
								>
									<p>
										Hi there,
										<br></br>I am{'  '}
										<Input
											className='inline-block w-max text-blue-700'
											classNames={{
												input: ['text-blue-700'],
											}}
											type='text'
											// className='rounded-lg inline border-2 border-blue-700 px-3 py-1 outline-none text-blue-700 my-1'
											name='name'
											placeholder='Mr x'
											value={formData.name}
											onChange={handleInputChange}
											required
										/>
										{'  '}
										from{'  '}
										<Input
											className='inline-block w-max text-blue-700'
											type='text'
											name='company'
											placeholder='Company name'
											value={formData.company}
											onChange={handleInputChange}
											// className='rounded-lg inline border-2 border-blue-700 px-3 py-1 outline-none text-blue-700'
										/>
										, want to create something amazing with you guys!
									</p>
									<p>
										I would like to have a chat about{'  '}
										<Input
											className='inline-block w-max text-blue-700'
											type='text'
											name='project'
											placeholder='Project name'
											value={formData.project}
											onChange={handleInputChange}
											// className='rounded-lg inline border-2 border-blue-700 px-3 py-1 outline-none text-blue-700'
										/>{' '}
										and hop on asap.
									</p>
									<p>
										Fix a schedule then and notify me at{'  '}
										<Input
											className='inline-block w-max text-blue-700'
											type='email'
											name='email'
											placeholder='email@email.com'
											value={formData.email}
											onChange={handleInputChange}
											// className='rounded-lg inline border-2 border-blue-700 px-3 py-1 outline-none text-blue-700'
										/>
										.
									</p>
									<p>Let's get this going!</p>

									<Button
										className='bg-blue-700 text-white w-full mb-2 rounded-md'
										onPress={onOpen}
									>
										Submit
									</Button>
								</form>
							}
						</AccordionItem>
					</Accordion>
				</div>
			</div>

			<Modal
				isOpen={isOpen}
				onOpenChange={onOpenChange}
				size={'sm'}
				backdrop={'blur'}
				classNames={{
					header: 'justify-center',
					closeButton: 'hidden',
				}}
			>
				<ModalContent>
					{(onClose) => (
						<>
							<ModalHeader b>
								⚡ Mission Initiated: You're on the Radar!
							</ModalHeader>
							<ModalBody>
								<p>
									Your amazing request is already speeding through
									cyberspace at light speed! 🛸 We're strapping in for
									an exciting ride. Get ready, we'll be in touch soon
									to kickstart something incredible together! 🌟
								</p>
							</ModalBody>
							<ModalFooter>
								<Button
									onPress={onClose}
									className='rounded-md w-full mb-2 bg-blue-700 text-white'
								>
									Close
								</Button>
							</ModalFooter>
						</>
					)}
				</ModalContent>
			</Modal>
		</section>
	);
}
