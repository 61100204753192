import { Image, Link } from '@nextui-org/react';

import Logo from '../../../assets/logo.png';

const Footer = () => {
	return (
		<footer className='bg-blue-700 text-white py-6'>
			<div className='max-w-7xl mx-auto px-4 sm:px-6 lg:px-8'>
				<div className='flex flex-wrap justify-center items-center sm:justify-between'>
					<span className='my-4 sm:m-0'>©2024 Optimaxsoft. All rights reserved.</span>
					<div className='w-full sm:w-0'></div>
					<div className=''>
						<Link href='/'>
							<Image
								src={Logo}
								alt='OptimaxSoft Logo'
								width={200}
								className='bg-white p-2 rounded-md opacity-100'
							/>
						</Link>
					</div>
				</div>
			</div>
		</footer>
	);
};

export default Footer;
