import { FileStack, PackageCheck, CodeXml, WandSparkles } from 'lucide-react';

export default function ProcessSection() {
	return (
		<section className='bg-white lg:p-16 py-16 px-8' id='roadmap'>
			<div className='max-w-6xl mx-auto'>
				<div className='grid grid-cols-1 md:grid-cols-3 gap-6 items-center'>
					<div className='md:col-span-2 grid grid-cols-1 sm:grid-cols-2 gap-6 order-last md:order-first'>
						{[
							{
								icon: FileStack,
								title: 'Your requirements',
								step: 1,
								description: '',
							},
							{ icon: WandSparkles, title: 'Designing UI', step: 2 },
							{ icon: CodeXml, title: 'Coding the software', step: 3 },
							{
								icon: PackageCheck,
								title: 'Delivery and Support',
								step: 4,
							},
						].map((item, index) => (
							<div
								key={index}
								className='bg-blue-700 rounded-lg p-6 text-white text-start '
							>
								<item.icon className='w-8 h-8 mb-4' />
								<h3 className='text-xl font-bold mb-2'>
									{item.step}. {item.title}
								</h3>
								<p className='text-base '>
									Gather and understand clients need and expectations
								</p>
							</div>
						))}
					</div>
					<div className='md:col-span-1 text-center md:text-end'>
						<h2 className='text-3xl md:text-4xl font-bold mb-4 text-blue-700'>
							Way to Your Goal
						</h2>
						<p className='text-base md:text-lg leading-relaxed text-blue-700'>
							Our process takes your project from concept to completion.
							We gather your requirements, design a user-friendly UI,
							code the software with quality and security in mind, and
							ensure timely delivery with ongoing support to keep
							everything running smoothly.
						</p>
					</div>
				</div>
			</div>
		</section>
	);
}
