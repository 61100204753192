'use client';

import React, { useEffect, useRef, useState } from 'react';
import TechLogos from './logos';

const logos = TechLogos.logos;

export default function OurTechnologies() {
	const containerRef = useRef(null);
	const [logoStates, setLogoStates] = useState([]);
	const animationRef = useRef();
	const [containerSize, setContainerSize] = useState({ width: 0, height: 0 });

	useEffect(() => {
		const container = containerRef.current;
		if (!container) return;

		const updateSize = () => {
			const rect = container.getBoundingClientRect();
			setContainerSize({ width: rect.width, height: rect.height });
		};

		updateSize();
		window.addEventListener('resize', updateSize);

		return () => window.removeEventListener('resize', updateSize);
	}, []);

	useEffect(() => {
		if (containerSize.width === 0 || containerSize.height === 0) return;

		const containerRect = containerRef.current?.getBoundingClientRect() || {
			width: 0,
			height: 0,
		};

		const initialStates = logos.map(() => ({
			x: Math.random() * (containerRect.width - 48),
			y: Math.random() * (containerRect.height - 48),
			vx: (Math.random() - 0.5) * 2,
			vy: (Math.random() - 0.5) * 2,
			isDragging: false,
			rotation: Math.random() * 360,
		}));

		setLogoStates(initialStates);

		const animate = () => {
			setLogoStates((prevStates) =>
				prevStates.map((state) => {
					if (state.isDragging) return state;

					let { x, y, vx, vy, rotation } = state;

					x += vx;
					y += vy;
					rotation += 1; // Slowly rotate the logo

					if (x <= 0 || x >= containerRect.width - 48) {
						vx = -vx;
						x = x <= 0 ? 0 : containerRect.width - 48;
					}

					if (y <= 0 || y >= containerRect.height - 48) {
						vy = -vy;
						y = y <= 0 ? 0 : containerRect.height - 48;
					}

					return { ...state, x, y, vx, vy, rotation };
				})
			);

			animationRef.current = requestAnimationFrame(animate);
		};

		animate();

		return () => {
			if (animationRef.current) {
				cancelAnimationFrame(animationRef.current);
			}
		};
	}, [containerSize]);

	return (
		<section
			className='bg-blue-700 text-white lg:p-16 py-16 px-8'
			id='technologies'
		>
			<div className='max-w-6xl mx-auto flex flex-col lg:flex-row items-center justify-between gap-8'>
				<div className='w-full lg:w-1/2 lg:text-start text-center'>
					<h2 className='text-3xl md:text-4xl font-bold mb-5'>
						Skillset in Action
					</h2>
					<p className='text-base md:text-lg leading-relaxed'>
						Our innovative technologies empower businesses to streamline
						operations, enhance customer experiences, and drive growth.
						Leveraging cutting-edge solutions, we deliver efficiency,
						scalability, and security to meet the needs of today and
						tomorrow.
					</p>
				</div>
				<div
					ref={containerRef}
					className='w-full lg:w-1/2 bg-white rounded-2xl p-4 md:p-8 relative overflow-hidden'
					style={{ height: '300px', minHeight: '300px' }}
				>
					{logos.map((logo, index) => (
						<div
							key={index}
							className='absolute w-12 h-12'
							style={{
								left: `${logoStates[index]?.x ?? 0}px`,
								top: `${logoStates[index]?.y ?? 0}px`,
								transform: `rotate(${
									logoStates[index]?.rotation ?? 0
								}deg)`,
								transition: 'transform 0.1s ease-out',
								filter: `drop-shadow(0 0 4px ${logo.fill})`,
							}}
						>
							{logo.svg}
						</div>
					))}
				</div>
			</div>
		</section>
	);
}
