import Logo from '../../../assets/logo.png';

import React from 'react';
import { Link, Image } from '@nextui-org/react';
import { Menu, X } from 'lucide-react';

import { useState, useRef, useEffect } from 'react';

const Navbar = () => {
	const navBarComponentStyles = {
		navBarLink: 'mx-2',
		navBarSpan:
			'text-sm relative visible before:absolute before:bottom-0 before:left-0 before:h-0.5 before:w-full before:origin-right before:scale-x-0 before:bg-blue-700 before:transition-transform before:duration-300 hover:before:origin-left hover:before:scale-x-100 text-dark-blue',
		navBar:
			'z-50 max-w-7xl h-auto bg-white rounded-xl my-4 border border-blue-700 shadow-md fixed top-0 left-0 right-0 py-2 mx-auto sm:m-4 md:m-4',
	};

	const [isMenuOpen, setIsMenuOpen] = useState(false);
	const dropdownRef = useRef(null);
	const buttonRef = useRef(null);

	useEffect(() => {
		function handleClickOutside(event) {
			if (
				dropdownRef.current &&
				!dropdownRef.current.contains(event.target) &&
				buttonRef.current &&
				!buttonRef.current.contains(event.target)
			) {
				setIsMenuOpen(false);
			}
		}

		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, []);

	const toggleMenu = () => {
		setIsMenuOpen((prevState) => !prevState);
	};

	return (
		<nav className='z-50 bg-white rounded-xl shadow-md m-4 xl:mx-auto max-w-7xl border border-blue-700 h-auto fixed top-0 left-0 right-0 py-2 md:m-4 sm:m-4'>
			<div className='mx-auto pl-4 sm:pl-6 lg:pl-8'>
				<div className='flex items-center justify-between align-middle'>
					<div className='flex-shrink-0 align-middle'>
						{/* <Link href='/'> */}
						<Image
							src={Logo}
							alt='OptimaxSoft Logo'
							width={150}
							className='opacity-100'
						/>
						{/* </Link> */}
					</div>
					<div className='hidden md:block'>
						<div className='ml-10 flex items-baseline space-x-4'>
							<Link
								href='#about'
								className={navBarComponentStyles.navBarLink}
							>
								<p class='text-sm group relative w-max text-blue-700'>
									<span>Our Note</span>
									<span class='absolute -bottom-1 left-1/2 w-0 transition-all h-0.5 bg-blue-700 group-hover:w-3/6'></span>
									<span class='absolute -bottom-1 right-1/2 w-0 transition-all h-0.5 bg-blue-700 group-hover:w-3/6'></span>
								</p>
							</Link>
							<Link
								href='#services'
								className={navBarComponentStyles.navBarLink}
							>
								<p class='text-sm group relative w-max text-blue-700'>
									<span>What We Do</span>
									<span class='absolute -bottom-1 left-1/2 w-0 transition-all h-0.5 bg-blue-700 group-hover:w-3/6'></span>
									<span class='absolute -bottom-1 right-1/2 w-0 transition-all h-0.5 bg-blue-700 group-hover:w-3/6'></span>
								</p>
							</Link>
							<Link
								href='#technologies'
								className={navBarComponentStyles.navBarLink}
							>
								<p class='text-sm group relative w-max text-blue-700'>
									<span>Our Mastery</span>
									<span class='absolute -bottom-1 left-1/2 w-0 transition-all h-0.5 bg-blue-700 group-hover:w-3/6'></span>
									<span class='absolute -bottom-1 right-1/2 w-0 transition-all h-0.5 bg-blue-700 group-hover:w-3/6'></span>
								</p>
							</Link>
							<Link
								href='#roadmap'
								className={navBarComponentStyles.navBarLink}
							>
								<p class='text-sm group relative w-max text-blue-700'>
									<span>The Journey</span>
									<span class='absolute -bottom-1 left-1/2 w-0 transition-all h-0.5 bg-blue-700 group-hover:w-3/6'></span>
									<span class='absolute -bottom-1 right-1/2 w-0 transition-all h-0.5 bg-blue-700 group-hover:w-3/6'></span>
								</p>
							</Link>
							<Link
								href='#contact'
								className={navBarComponentStyles.navBarLink}
							>
								<button
									className='rounded-md bg-blue-700 py-1 px-4 border border-transparent text-center text-sm text-white transition-all shadow-md hover:shadow-lg focus:bg-blue-500 focus:shadow-none active:bg-blue-700 hover:bg-blue-500 active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none mx-2 text-nowrap'
									type='button'
								>
									Let’s Connect!
								</button>
							</Link>
						</div>
					</div>
					<div className='md:hidden'>
						<button
							ref={buttonRef}
							onClick={toggleMenu}
							className='inline-flex items-center justify-center p-2 rounded-md text-blue-700 hover:text-blue-700 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-800 mx-2'
						>
							<span className='sr-only'>Open main menu</span>
							<Menu
								className={`h-6 w-6 transition-opacity duration-300 ease-in-out ${
									isMenuOpen ? 'invisible absolute' : 'visible'
								}`}
							/>
							<X
								className={`h-6 w-6 transition-opacity duration-300 ease-in-out ${
									isMenuOpen ? 'visible' : 'invisible absolute'
								}`}
							/>
						</button>
					</div>
				</div>
			</div>

			<div
				ref={dropdownRef}
				className={`absolute right-0 mt-4 w-48 bg-white rounded-md shadow-lg py-1 z-10 transition-all duration-300 ease-in-out ${
					isMenuOpen
						? 'visible scale-100'
						: 'invisible scale-95 pointer-events-none'
				}`}
			>
				<Link
					href='#about'
					className='block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100'
				>
					Our Note
				</Link>
				<Link
					href='#services'
					className='block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100'
				>
					What We Do
				</Link>
				<Link
					href='#technologies'
					className='block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100'
				>
					Our Mastery
				</Link>
				<Link
					href='#roadmap'
					className='block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100'
				>
					The Journey
				</Link>
				<Link
					href='#contact'
					className='block px-4 py-2 text-sm text-blue-600 hover:bg-gray-100'
				>
					Let’s Connect!
				</Link>
			</div>
		</nav>
	);
};

export default Navbar;
