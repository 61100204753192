import { NextUIProvider } from '@nextui-org/react';
import './App.css';
import Footer from './app/components/footer/footer.component';
import Navbar from './app/components/navbar/navbar.component';
import HomePage from './app/pages/home/home.page';

function App() {
	return (
		<div className='App'>
			<NextUIProvider>
				<Navbar />
				<div className='h-24 bg-blue-700'></div>
				<HomePage />
				<Footer />
			</NextUIProvider>
		</div>
	);
}

export default App;
